import { AfterViewInit, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { S3Service } from 'app/shared/services/s3/s3.service';
import * as FileSaver from 'file-saver';
import MobileDetect from 'mobile-detect';
import { Subscription } from 'rxjs';
import { SiteConfig } from '../../../config/site-config';
import { EmailShippingLabelComponent } from '../../drop-shipment/email-shipping-label/email-shipping-label.component';
import { ShipDetailsComponent } from '../../shared/components/ship-details/ship-details.component';
import { NotificationType } from '../../shared/models/notification-type';
import { IShipmentPackage } from '../../shared/models/shipments/shipment-package.interface';
import { ShipmentStatus } from '../../shared/models/shipments/shipment-status.enum';
import { ConfirmDialogComponent } from '../../shared/services/dialog/confirm-dialog/confirm-dialog.component';
import { ErrorHandlerService } from '../../shared/services/error-handler/error-handler.service';
import { BlobService } from '../../shared/services/file/blob.service';
import { NotificationService } from '../../shared/services/notification/notification.service';
import { UserService } from '../../shared/services/user/user.service';
import { UtilityService } from '../../shared/services/utility/utility.service';
import { ShipmentService } from '../../ship/services/shipment.service';
import { ShipmentHistoryService } from '../services/shipment-history.service';

// import { SpinnerService }from '../../core/services/spinner/spinner.service';

@Component({
    selector: 'upsc-shipment-detail',
    templateUrl: './shipment-detail.component.html',
    styleUrls: ['./shipment-detail.component.scss'],
})
export class ShipmentDetailComponent implements OnInit, AfterViewInit, OnDestroy {
    @ViewChild('shipDetails', { static: true }) public shipDetails: ShipDetailsComponent;
    // @ViewChild('dialogSpinner') public dialogSpinner: ElementBlockerComponent;

    public shipmentdetails: IShipmentPackage;
    public isDownloadingCommercialInvoice = false;
    public isShipmentDetailsLoading = false;
    public isAllowShipmentEdit = false;
    public isPrintReturnLabel = true;
    public showReprintCommercialInvoice = false;
    public isReturnService = false;
    public isNAFTACertificate = false;
    public carrier = '';
    public shipmentId: string;

    private getCommercialInvoiceSubscription: Subscription;
    private downloadFileSubscription: Subscription;

    constructor(public shipmentservice: ShipmentHistoryService,
                private shipmentService: ShipmentService,
                @Inject(MAT_DIALOG_DATA) public data: any,
                public confirmDialogRef: MatDialogRef<ConfirmDialogComponent>,
                private notificationService: NotificationService,
                private utilityService: UtilityService,
                private s3Service: S3Service,
                private blobService: BlobService,
                private errorHandlerService: ErrorHandlerService,
                public selfDialogRef: MatDialogRef<ShipmentDetailComponent>,
                private translateService: TranslateService,
                // private spinnerService: SpinnerService,
                private cdRef: ChangeDetectorRef,
                public dialog: MatDialog,
                private userService: UserService,
    ) {
        this.shipmentId = data.shipmentId;
    }

    public ngOnInit() {
    }

    public ngAfterViewInit() {
        this.GetShipment(this.shipmentId);
    }

    public ngOnDestroy() {
        this.utilityService.clearSubscriptions([this.getCommercialInvoiceSubscription, this.downloadFileSubscription]);
    }

    GetShipment(shipmentid: string) {
        this.isShipmentDetailsLoading = true; // this.spinnerService.show();
        this.cdRef.detectChanges();
        // this.setDialogSpinnerHeight();
        this.shipmentservice.getShipment(shipmentid)
            .subscribe(
                (shipmentPackage) => {
                    this.isShipmentDetailsLoading = false; // this.spinnerService.show();

                    this.shipmentdetails = shipmentPackage;
                    this.carrier = shipmentPackage?.CarrierCode.toString() === 'FEDEX' ? 'FedEx' : shipmentPackage?.CarrierCode.toString();

                    const isEditableShipmentStatus = shipmentPackage.Status === ShipmentStatus.BIRNew
                        || shipmentPackage.Status === ShipmentStatus.LabelPrinted;
                    this.isAllowShipmentEdit = SiteConfig.shouldAllowShipmentEdit && isEditableShipmentStatus;

                    // MV3-6047: Edit button still appearing for InputType other than web
                    if (this.shipmentdetails?.InputType?.toUpperCase() !== "WEB") {
                        this.isAllowShipmentEdit = false;
                    }

                    const data = <any>shipmentPackage;
                    this.isNAFTACertificate = data.IsNAFTACertificate;
                    this.isReturnService = data.IsReturnService;
                    this.isPrintReturnLabel = (this.isReturnService && data.ReturnServiceCode !== '9') ? false : true;
                    this.showReprintCommercialInvoice = (this.isReturnService && data.IsCommercialInvoice && (data.CommercialInvoiceType == '1'));
                    this.carrier = data?.CarrierCode.toString() === 'FEDEX' ? 'FedEx' : data?.CarrierCode.toString();
                },
                (error) => {
                    this.isShipmentDetailsLoading = false; // this.spinnerService.show();
                    this.notificationService.notify(
                        this.errorHandlerService.getHttpErrorMessage(error),
                        'Error Loading Shipment ',
                        NotificationType.ERROR);
                });
    }

    printWindow(shipmentid: string) {
        this.userService.getCurrentUser().subscribe(
            (currentUser: any) => {
                if (!currentUser) {
                    console.error('Failed retrieving current user');
                    return;
                }

                window.open('https://parcelpro.com/printnormal.aspx?shipmentid=' + shipmentid + '&sessionid=' + currentUser.sessionid, '_blank', 'location=yes,height=570,width=520,scrollbars=yes,status=yes');
            },
        );
    }

    voidConfirmation(shipmentid: string) {
        const dialogConfig: MatDialogConfig = {
            disableClose: true,
            data: {
                message: this.translateService.instant('Msg_RemoveShipmentConfirmation'),
            },
        };

        this.confirmDialogRef = this.dialog.open(ConfirmDialogComponent, dialogConfig);

        this.confirmDialogRef.afterClosed().subscribe(
            (result) => {
                if (result) {
                    this.VoidShipment(shipmentid);
                }
            });
    }

    // @ViewChild('VoidedInformation') VoidedInfo: ElementRef;
    VoidShipment(shipmentid: string) {
        this.shipmentservice.deleteShipment(shipmentid)
            .subscribe(
                (response) => {
                    this.notificationService.notify(
                        'Your shipment has been voided. This will also void any return label generated with it. ' +
                        'Please note this has canceled insurance coverage.',
                        'Successfully Voided Shipment',
                        NotificationType.SUCCESS);

                    this.selfDialogRef.close('voided');
                    // Refresh the data so in voided status record, "Void" button will not be shown
                    // this.data = [];
                    // this.GetData(this.pageIndex.toString());
                },
                (error) => {
                    this.notificationService.notify(
                        this.errorHandlerService.getHttpErrorMessage(error),
                        'Error Voiding Shipment ',
                        NotificationType.ERROR);
                });
    }

    openShippingLabel(shipmentId: string) {
        const dialogConfig: MatDialogConfig = {
            disableClose: true,
            maxWidth: '60em',
            data: {
                shipmentId,
            },
        };

        const dialogRef = this.dialog.open(EmailShippingLabelComponent, dialogConfig);

        dialogRef.afterClosed().subscribe(
            (result) => {
            });

    }

    public printCommercialInvoice(shipmentId: string) {
        this.isDownloadingCommercialInvoice = true;

        this.utilityService.clearSubscriptions([this.getCommercialInvoiceSubscription]);
        this.getCommercialInvoiceSubscription = this.shipmentService.getCommercialInvoice(shipmentId)
                                                    .subscribe(
                                                        base64 => this.handleGetCommercialInvoiceSuccess(base64),
                                                        err => this.handleGetCommercialInvoiceFailure(err),
                                                    );
    }

    public printShipmentDetails(event) {
        if (event) {
            event.preventDefault();
        }

        this.shipDetails.printShipmentDetails();
    }

    public printNAFTAForm(carrier: string) {
        if (carrier === 'UPS' || carrier === 'FedEx') {
            this.downloadFileSubscription = this.s3Service.getFile('members-pdf.upsc', `USMCA-${ carrier }.pdf`)
                                                .subscribe(
                                                    base64 => {
                                                        const blob = this.blobService.base64PdfToBlob(base64);
                                                        FileSaver.saveAs(blob, `USMCA-${ carrier }.pdf`);
                                                    },
                                                    err => console.error(err),
                                                );
        }
    }

    public viewPendingShipments() {
    }

    public shouldShowButtonEmailLabel(shipment: IShipmentPackage): boolean {
        // MV3-5957: Hide all buttons in shipment details page if InputType != "Web"
        if (!shipment || shipment?.InputType?.toUpperCase() !== "WEB") {
            return false;
        }

        return shipment.Status !== 3 && this.isPrintReturnLabel &&
            (shipment.Status === 4 ||
                shipment.Status === 10 ||
                shipment.Status === 1 ||
                shipment.Status === 0);
    }

    public shouldShowButtonReship(shipment: IShipmentPackage): boolean {
        if (!shipment || shipment?.InputType?.toUpperCase() !== "WEB") {
            return false;
        }

        const isAESShipment = shipment.FilingOption === 1;
        const isHighValueShipment = shipment.IsHighValueShipment;
        const isConfirmedHighValueShipment = isHighValueShipment && shipment.Status === ShipmentStatus.LabelPrinted;

        return (!isHighValueShipment && !isAESShipment) || isConfirmedHighValueShipment;
    }

    public shouldShowButtonPrintLabel(shipment: IShipmentPackage): boolean {
        if (!shipment || shipment?.InputType?.toUpperCase() !== "WEB") {
            return false;
        }

        const md = new MobileDetect(window.navigator.userAgent);
        if (!!md.mobile()) {
            return false;
        }

        return shipment.Status !== 3 && this.isPrintReturnLabel &&
            (shipment.Status === 4 ||
                shipment.Status === 10 ||
                shipment.Status === 1 ||
                shipment.Status === 0);
    }

    public shouldShowButtonDownloadLabel(shipment: IShipmentPackage): boolean {
        if (!shipment || shipment?.InputType?.toUpperCase() !== "WEB") {
            return false;
        }

        const md = new MobileDetect(window.navigator.userAgent);

        return shipment.Status !== 3 && this.isPrintReturnLabel &&
            (shipment.Status === 4 ||
                shipment.Status === 10 ||
                shipment.Status === 1 ||
                shipment.Status === 0);
    }

    public shouldShowButtonPrintCommercialInvoice(shipment: IShipmentPackage): boolean {
        if (!shipment || shipment?.InputType?.toUpperCase() !== "WEB") {
            return false;
        }

        return shipment.IsCommercialInvoice && shipment.Status !== 3 && this.isPrintReturnLabel; // (this.isPrintReturnLabel || this.showReprintCommercialInvoice); - commented as there is an issue in API
    }

    public shouldShowButtonPrintShipmentDetails(shipment: IShipmentPackage): boolean {
        if (!shipment || shipment?.InputType?.toUpperCase() !== "WEB") {
            return false;
        }
        
        return !!shipment;
    }

    public shouldShowButtonVoid(shipment: IShipmentPackage): boolean {
        if (!shipment || shipment?.InputType?.toUpperCase() !== "WEB") {
            return false;
        }

    return shipment.Status !== 3 &&
      (shipment.Status === 4 ||
        shipment.Status === 10 ||
        shipment.Status === 1 ||
        shipment.Status === 0);
  }

  public shouldShowButtonPOD(shipment: IShipmentPackage): boolean {
    if (!shipment) {
        return false;
    }

    return shipment.POD.HasPOD;
  }

    public onShipmentVoiding(isVoiding: boolean) {
        this.isShipmentDetailsLoading = isVoiding;
        // this.setDialogSpinnerHeight();
    }

    private handleGetCommercialInvoiceSuccess(base64: string) {
        this.isDownloadingCommercialInvoice = false;
        const blob = this.blobService.base64PdfToBlob(base64);
        FileSaver.saveAs(blob, 'commercial-invoice.pdf');
    }

    private handleGetCommercialInvoiceFailure(error) {
        this.notificationService.notify(
            this.errorHandlerService.getHttpErrorMessage(error),
            'Failed Downloading Commercial Invoice',
            NotificationType.ERROR);
        this.isDownloadingCommercialInvoice = false;
    }

    /* private setDialogSpinnerHeight() {
      if (!this.dialogSpinner) {
        return;
      }

      const dialogContentHeight = document.querySelector('.mat-dialog-container')['offsetHeight'];
      this.dialogSpinner.setHeight(`${dialogContentHeight - 55}px`);
    }*/
}
